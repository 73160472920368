<template>
  <page fluid="lg" title="Crear indicadores">
    <formulario ref="frm" :indicador="indicador" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiIndicadores from "@/services/indicadores.js";

export default {
  components: { Formulario },
  data() {
    return {
      indicador: apiIndicadores.crear(),
    };
  },
  methods: {
    async insertar({ indicador, relacionados, documento }) {
      const response = await apiIndicadores.insertar(indicador, relacionados);

      if (documento.name) {
        await apiIndicadores.importar(response.id, documento);
      }

      this.$emit("userMessage", "Se ha creado el indicador");
      this.$router.push({ path: "/administracion/indicadores" });
    },
  },
  mounted() {
    this.$refs.frm.enfocar();
  },
};
</script>
